import { useCallback } from 'react';
import { useReactFlow, useStore } from '@xyflow/react';
import styles from './styles.module.css';

const NodeAttributesPanel = () => {
  const { setNodes } = useReactFlow();
  const selectedNode = useStore(useCallback((state) => 
    state.nodes.find((node) => node.selected)
  , []));

  if (!selectedNode) {
    return (
      <div className={styles.panel}>
        <h4>Properties</h4>
        <p className={styles.empty}>Select a node to edit properties</p>
      </div>
    );
  }

  const updateNodeData = (key: string, value: any) => {
    setNodes(nodes => nodes.map(node => {
      if (node.id === selectedNode.id) {
        return {
          ...node,
          data: { ...node.data, [key]: value }
        };
      }
      return node;
    }));
  };

  return (
    <div className={styles.panel}>
      <h4>Properties</h4>
      <div className={styles.field}>
        <label htmlFor="node-label">Label</label>
        <input
          id="node-label"
          type="text"
          value={typeof selectedNode.data.label === 'string' ? selectedNode.data.label : ''}
          onChange={e => updateNodeData('label', e.target.value)}
          placeholder="Enter label"
        />
      </div>
      <div className={styles.field}>
        <label htmlFor="node-color">Color</label>
        <input
          id="node-color"
          type="color"
          value={typeof selectedNode.data.color === 'string' ? selectedNode.data.color : '#ffffff'}
          onChange={e => updateNodeData('color', e.target.value)}
        />
      </div>
    </div>
  );
};

export default NodeAttributesPanel;
