import { DocumentAnalysisClient, AzureKeyCredential } from '@azure/ai-form-recognizer';

const formRecognizerEndpoint = "https://circuitry-azure-document-intel.cognitiveservices.azure.com/";
const formRecognizerApiKey = "95102f399a01444db1a1bafb9d17f81e";

const formRecognizerClient = new DocumentAnalysisClient(formRecognizerEndpoint, new AzureKeyCredential(formRecognizerApiKey));

export async function extractTextAndPolygons(file: File) {
  const poller = await formRecognizerClient.beginAnalyzeDocument("prebuilt-layout", file);
  const result = await poller.pollUntilDone();

  const pages = result.pages ? result.pages.map(page => ({
    width: page.width,
    height: page.height,
    lines: page.lines ? page.lines.map(line => ({
      text: line.content,
      polygon: line.polygon
    })) : []
  })) : [];

  return pages;
}