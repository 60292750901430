import { NodeTypes } from '@xyflow/react';

import PlaceholderNode from './PlaceholderNode';

// two different node types are needed for our example: workflow and placeholder nodes
export const nodeTypes: NodeTypes = {
  placeholder: PlaceholderNode,
};

export default nodeTypes;
