const transcribeAllowedFiles = [
    "video/mp4",       // MP4
    "video/webm",      // WebM
    "video/ogg",       // Ogg Video
    "video/mpeg",      // MPEG
    "audio/mpeg",      // MP3
    "audio/ogg",       // Ogg Audio
    "audio/wav",       // WAV
    "audio/aac",       // AAC
];

const imageAllowedTypes = [
    "image/jpeg",      // JPEG
    "image/png",       // PNG
    "image/gif",       // GIF
    "image/webp",      // WebP
];

export { transcribeAllowedFiles, imageAllowedTypes };