type NodeLabelProps = {
  readonly placeholder: string;
  readonly label: string|undefined;
  readonly onLabelChange: (label: string) => void;
};
function NodeLabel({ placeholder, label, onLabelChange }: NodeLabelProps) {
  return (
    <input type='text' className='node-label' 
      placeholder={placeholder} value={label} 
      onChange={(event) => onLabelChange(event.target.value)}
    />
  );
}

export default NodeLabel;
