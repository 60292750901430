import {
  AbilityContext,
  checkType,
  CtyShowToast,
  injectPalChatbot,
  useScreenWidth,
  type SidebarItem,
} from '@circuitry-ai/doc-ui';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

const SidebarItems = (isCollapsed: boolean) => {
  const { t } = useTranslation();
  const ability = useContext(AbilityContext);
  const width = useScreenWidth();
  const isMobile = width < 640;

  return [
    {
      key: 'toggle',
      title: t('Toggle'),
      icon: 'solar:hamburger-menu-outline',
      className: 'mb-4 mt-1',
      show: isCollapsed,
    },
    {
      key: 'dashboard',
      href: '/',
      icon: 'radix-icons:home',
      title: t('Dashboard'),
    },
    {
      key: 'octr',
      title: t('AI Assistants'),
      items: [
        {
          key: 'advisors',
          href: '/advisors',
          icon: 'octicon:people-24',
          title: t('AIdvisors'),
          show: ability.can("view", "Advisors"),
        },
        {
          key: 'agents',
          href: '/agents',
          icon: 'carbon:workflow-automation',
          title: t('AIgents'),
          show: ability.can("list", "Agent") && !window.location.hostname.includes('kingspan'),
        },
        {
          key: 'analyst',
          href: '/analytics',
          icon: 'hugeicons:chart-bar-line',
          title: t('AInalysts'),
          show: ability.can("analytics", "Dashboard")
        }
      ]
    },
    {
      key: 'agent',
      title: t('Intelligence Platform'),
      items: [
        {
          key: 'knowledge',
          href: '/knowledge',
          icon: 'lucide:copy-check',
          title: t('Knowledge'),
        },
        {
          key: 'conversations',
          href: '/conversations',
          icon: 'ph:chat-text',
          title: t('Conversations'),
          show: ability.can("viewhistory", "Conversations"),
        },
        // {
        //   key: 'conversationwithmilves',
        //   href: '/search-with-milvus',
        //   icon: 'ph:chat-text',
        //   title: t('Search With Milves'),
        // }
      ]
    },
    {
      key: 'sys',
      title: t('System'),
      items: [
        {
          key: 'settings',
          href: '/settings',
          icon: 'qlementine-icons:settings-16',
          title: t('Settings'),
        },
        {
          key: 'help',
          // href: "/help",
          icon: 'tdesign:support',
          title: t('Support'),
        },
      ],
    },
    {
      key: 'pal',
      title: t('Launch PAL'),
      show: isMobile,
      items: [
        {
          key: 'New Window',
          icon: 'fluent:window-new-20-regular',
          title: t('New Window'),
          onClick: () => {
            window.open(
              'https://pal.circuitry.ai/',
              '_blank',
              'noopener,noreferrer'
            );
          },
        },
        {
          key: 'Widget',
          icon: 'heroicons:chat-bubble-left-right',
          title: t('Widget'),
          onClick: () => {
            injectPalChatbot(CtyShowToast, checkType);
          },
        },
      ],
    },
  ] as SidebarItem[];
};

export default SidebarItems;
