import React, { useEffect, useRef, useState } from 'react';
import { CtyButton, CtyHeader, Sidenav } from "@circuitry-ai/doc-ui";
import { Image, useDisclosure } from '@nextui-org/react';
import SidebarItems from '../../assets/Sidebaritems';
import { PressEvent } from '@react-types/shared';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Icon } from '@iconify/react';


type Props = {
  children: React.ReactNode;
};
type CustomTitles = {
  [key: string]: string;
};
const customTitles: CustomTitles = {
  dashboard: "Dashboard",
  advisors: "AIdvisors",
  conversations: "Conversations",
  knowledge: "Knowledge",
  analytics: "AInalysts",
  pdfscanner: "Pdf Scanner",
  ocrscanner: "OCR Scanner",
  automation: "Automations",
  settings: "Settings",
  analyst: "AInalysts",

};
const Layout = ({ children }: Props) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const { logout, loginWithRedirect } = useAuth0();
  const pathname = useLocation().pathname;
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const sidebarItems = SidebarItems(isCollapsed);
  const pathnameRef = useRef(pathname);
  const sidenavRef = useRef(null);
  const navigate = useNavigate();

  const handleToggleSidenav = () => {
    if (sidenavRef.current) {
      sidenavRef.current?.toggle();
    }
  };

  useEffect(() => {
    pathnameRef.current = pathname;
  }, [pathname]);
  const currentLocation = pathnameRef.current.split('/')[1] || 'dashboard';
  useEffect(() => {
    document.title = customTitles[currentLocation] || 'Circuitry AI';
  }, []);
  const onSideNavClick = (e: PressEvent) => {
    const currentLocation = e.target.getAttribute('data-key') || 'dashboard';
    document.title = customTitles[currentLocation] || 'Circuitry AI';
    if (currentLocation === "help") {
      let helpUrl = "https://customer.circuitry.ai/knowledge-base";
      const currentPath = pathnameRef.current;
      if (currentPath.includes("/createadvisor")) {
        helpUrl += "/getting-started-with-product-aidvisor";
      } else if (currentPath.includes("/advisors")) {
        helpUrl += "/viewing-and-editing-advisors";
      } else if (currentPath.includes("/conversations")) {
        helpUrl += "/viewing-conversation-history";
      } else if (currentPath.includes("/knowledge")) {
        helpUrl += "/using-the-knowledge-base";
      } else if (currentPath.includes("/settings")) {
        helpUrl += "/settings";
      } else {
        helpUrl += "/dashboard-view";
      }
      window.open(helpUrl, "_blank");
    }
    if (currentLocation === 'toggle') {
      handleToggleSidenav();
    }
    if (isOpen) {
      onOpenChange();  // This closes the modal
    }
  };
  if (pathname.includes('/file/')) {
    return <div>{children}</div>;
  }

  const handleCollapse = (isCollapsed: boolean) => {
    setIsCollapsed(isCollapsed);
  };

  const handleOrganisationSwitch = (name: string) => {
    loginWithRedirect({
      authorizationParams: {
        organization: name
      }
    });
  };

  const userLogout = () => {
    logout({
      logoutParams: { returnTo: window.location.origin },
    });
  };

  const sidenavProps = {
    isOpen,
    onOpenChange,
    onClick: onSideNavClick,
    items: sidebarItems,
    onCollapseChange: handleCollapse,
    switchOrganisation: handleOrganisationSwitch,
    doLogout: userLogout,
  };

  return (
    <div className="flex flex-col h-screen ">
      <div className="flex fixed left-0 h-full w-full z-10">
        <div className='bg-white border-r border-color[#F2F4F7] bg-red-3d00 flex flex-col justify-between h-full'>
          {!isCollapsed && <div className='hidden sm:block'>
            <div className={`w-full flex justify-between gap-3 px-2 py-3 items-center ${!isCollapsed && 'border-b'}`}>
              <CtyButton className='flex gap-4 items-center h-[40px]' variant='light' disableRipple={true} size="sm" onPress={() => navigate('/')}>
                <Image src="/Logo.svg" alt="Logo" width={150} />
              </CtyButton>
              <CtyButton variant="light" size="sm" isIconOnly radius="full" disableRipple={true} onPress={handleToggleSidenav}>
                <Icon icon="hugeicons:layout-right" width="1.5em" height="1.5em" style={{ color: "#000" }} />
              </CtyButton>
            </div>
          </div>}
          <Sidenav ref={sidenavRef} {...sidenavProps} />
        </div>
        <div className={`flex flex-col h-full w-[100%]`}>
          <CtyHeader
            logo='/Logo.svg'
            onOpen={onOpen}
            isCollapsed={isCollapsed}
          />
          <div className='flex-1 overflow-y-scroll layout-content'>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;




