import React, { useState, useEffect } from 'react';

/* eslint-disable-next-line */
export interface JsonFormViewerProps {
  readonly jsonData: object;
}

export function JsonFormViewer({ jsonData }: JsonFormViewerProps) {
  const [formData, setFormData] = useState<Record<string, any>>(jsonData);
  const [collapsed, setCollapsed] = useState<Record<string, boolean>>({});

  useEffect(() => {
    setFormData(jsonData);
  }, [jsonData]);

  const toggleCollapse = (path: string) => {
    setCollapsed((prev) => ({
      ...prev,
      [path]: !prev[path],
    }));
  };

  const renderForm = (data: any, path: string[] = [], isNested = false) => {
    const currentPath = path.join('.');

    if (Array.isArray(data)) {
      return (
        <>
          <tr className="bg-gray-200 cursor-pointer" onClick={() => toggleCollapse(currentPath)}>
            <td colSpan={2} className="font-bold pl-3">{`${path[path.length - 1]} (${data.length})`}</td>
          </tr>
          {!collapsed[currentPath] &&
            data.map((item, index) => (
              <React.Fragment key={index}>
                <tr className="bg-gray-100">
                  <td colSpan={2} className="pl-4">{`Item ${index + 1}`}</td>
                </tr>
                {renderForm(item, [...path, String(index + 1)], true)}
              </React.Fragment>
            ))}
        </>
      );
    } else if (typeof data === 'object' && data !== null) {
      return (
        <>
          {isNested && (
            <tr className="bg-gray-200 cursor-pointer" onClick={() => toggleCollapse(currentPath)}>
              <td colSpan={2} className="font-bold pl-3">{path[path.length - 1]}</td>
            </tr>
          )}
          {!collapsed[currentPath] &&
            Object.keys(data).map((key) => (
              <React.Fragment key={`${currentPath}-${key}`}>
                {typeof data[key] === 'object' ? (
                  renderForm(data[key], [...path, key], true)
                ) : (
                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900" style={{ width: '30%', whiteSpace: 'normal', wordWrap: 'break-word' }}>{key}</td>
                    <td className="px-6 py-4 whitespace-normal break-words text-sm text-gray-500" style={{ width: '70%', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                      {data[key] !== undefined && data[key] !== null ? data[key] : 'N/A'}
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Field
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Value
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">{renderForm(formData)}</tbody>
      </table>
    </div>
  );
}

export default JsonFormViewer;