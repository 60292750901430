import { useState, useEffect } from 'react';

const useScreenWidth = (debounceDelay = 300) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    const handleResize = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setScreenWidth(window.innerWidth);
      }, debounceDelay);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup listener and timeout on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(timeoutId);
    };
  }, [debounceDelay]);

  return screenWidth;
};

export { useScreenWidth };