import React, { useRef, useEffect } from 'react';

/* eslint-disable-next-line */
export interface PDFViewerProps {
  readonly fileUrl: string;
}

export function PDFViewer(props: PDFViewerProps) {
  const { fileUrl } = props;
  const viewerRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (fileUrl) {
      if (viewerRef.current) {
        viewerRef.current.src = `/pdfjs/web/viewer.html?file=${fileUrl}`;
      }
    }
  }, [fileUrl]);

  return (
    <div className="w-full h-full relative sm:min-h-[400px] ">
      <iframe
        ref={viewerRef}
        title="PDF Viewer"
        className="w-full h-full border-none min-h-[400px]"
      />
    </div>
  );
}

export default PDFViewer;