import {
  NodeResizer,
  type NodeProps,
  useStore,
  Handle,
  Position,
  useKeyPress,
  useReactFlow,
} from '@xyflow/react';

import Shape from '../shape';
import useNodeActions from '../hooks/useNodeClick';
import ShapeNodeToolbar from '../toolbar';
import { type ShapeNode } from '../shape/types';
import NodeLabel from './label';

// this will return the current dimensions of the node (measured internally by react flow)
function useNodeDimensions(id: string) {
  const node = useStore((state) => state.nodeLookup.get(id));
  return {
    width: node?.measured?.width || 0,
    height: node?.measured?.height || 0,
  };
}

function ShapeNode({ id, selected, data }: NodeProps<ShapeNode>) {
  const { color='#ffffff', type } = data;
  const { setNodes } = useReactFlow();
  const { onDeleteClick } = useNodeActions(id);

  const { width, height } = useNodeDimensions(id);
  const shiftKeyPressed = useKeyPress('Shift');
  const handleStyle = { backgroundColor: color };

  const onColorChange = (color: string) => {
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              color,
            },
          };
        }

        return node;
      })
    );
  };

  const onLabelChange = (label: string) => {
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              label,
            },
          };
        }
        return node;
      })
    );
  };

  return (
    <>
      <svg style={{ width: 0, height: 0, position: 'absolute' }}>
        <defs>
          <linearGradient id={`stroke-${id}`} x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#D4007E" stopOpacity="0.8" />
            <stop offset="100%" stopColor="#003A79" stopOpacity="0.8" />
          </linearGradient>
        </defs>
      </svg>
      <ShapeNodeToolbar onColorChange={onColorChange} activeColor={color} onDelete={onDeleteClick} />
      <NodeResizer
        color={color}
        keepAspectRatio={shiftKeyPressed}
        isVisible={selected}
      />
      <Shape
        type={type}
        width={width}
        height={height}
        fill={color}
        strokeWidth={2}
        stroke={`url(#stroke-${id})`}
        fillOpacity={0.8}
      />
      <Handle
        style={handleStyle}
        id="top"
        type="target"
        position={Position.Top}
      />
      <Handle
        style={handleStyle}
        id="bottom"
        type="source"
        position={Position.Bottom}
      />
      <Handle
        style={handleStyle}
        id="right"
        type="source"
        position={Position.Right}
      />
      <Handle
        style={handleStyle}
        id="left"
        type="source"
        position={Position.Left}
      />
      <NodeLabel 
        placeholder={data.type} 
        label={data.label} 
        onLabelChange={onLabelChange}
      />
    </>
  );
}

export default ShapeNode;
