import { NodeToolbar, Position } from '@xyflow/react';
import { Icon } from '@iconify/react';


type ShapeNodeToolbarProps = {
  readonly activeColor: string;
  readonly onColorChange?: (color: string) => void;
  readonly onDelete?: () => void;
};

function ShapeNodeToolbar({
  onColorChange = () => false,
  onDelete,
  activeColor,
}: ShapeNodeToolbarProps) {
  return (
    <NodeToolbar 
      className="nodrag toolbar-right" 
      position={Position.Top}
      offset={5}
      align="end"
    >
      <button
        onClick={onDelete}
        className="delete-button"
        title="Delete node"
      >
        <Icon icon="solar:trash-bin-minimalistic-broken" width="16" height="16" />
      </button>
    </NodeToolbar>
  );
}

export default ShapeNodeToolbar;
