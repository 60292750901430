import { PartsSvgViewer } from "./partssvgviewer";
import { SchematicSvgViewer } from "./schematicsvgviewer";


interface SvgViewerContainerProps {
    svgUrl: string;
    sourceIdData: any;
    svgType: string;
}

function renderSvgViewer(svgUrl: string, sourceIdData: any, svgType: string) {
    switch (svgType) {
        case 'schematicsvg':
            return <SchematicSvgViewer svgUrl={svgUrl} sourceIdData={sourceIdData} />;
        case 'partssvg':
            return <PartsSvgViewer svgUrl={svgUrl} sourceIdData={sourceIdData} />;
        default:
            return <SchematicSvgViewer svgUrl={svgUrl} sourceIdData={sourceIdData} />;
    }
}
export default function SvgViewerContainer({ svgUrl, sourceIdData, svgType }: Readonly<SvgViewerContainerProps>) {
    return (
        <div className='flex flex-col h-screen '>
            {
                renderSvgViewer(svgUrl, sourceIdData, svgType)
            }
        </div>
    );
}


