import { useGetAgentsJSONMutation, useGetPDFUploadPresignedUrlMutation, useUploadFileToS3Mutation } from "@circuitry-ai/doc-data";
import { CommonFileUpload, CtyButton, CtyShowToast } from "@circuitry-ai/doc-ui";
import { useRef, useState } from "react";
import { transcribeAllowedFiles } from "./agentconstants";

/* eslint-disable-next-line */
export interface TranscriberProps { }


function Transcriber(props: TranscriberProps) {

  const [getPresignedUrl] = useGetPDFUploadPresignedUrlMutation();
  const [uploadtos3] = useUploadFileToS3Mutation();
  const [getOcrText] = useGetAgentsJSONMutation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [transcribeData, setTranscribeData] = useState<any>([]);
  const [mediaUrl, setMediaUrl] = useState<string | null>(null);
  const mediaRef = useRef<HTMLMediaElement>(null);
  const [uploadedFileType, setUploadedFileType] = useState<string>('');
  const [progress, setProgress] = useState<number>(0); // Progress state
  const [loadingText, setLoadingText] = useState<string>("Uploading..."); // Loading text state
  const handleFileUpload = async (files: FileList | null) => {
    const file = files ? files[0] : null;
    if (!file) return;
    setProgress(10); // Initial progress
    setLoadingText("Uploading File..."); // Reset loading text
    try {
      const payLoad = {
        agent_id: '31457ce4-b51d-471e-8858-04652a3ae35a',
        data: [{
          document_type: "transcribe",
          filename: file.name,
          parts: 0,
          file_type: file.type.split('/')[1]
        }]
      };

      const uploadURL: any = await getPresignedUrl(payLoad).unwrap();
      setProgress(30);
      await uploadtos3({
        file,
        presignedUrl: uploadURL.presigned_urls.part_1,
        CtyShowToast: () => { },

      }).unwrap();
      setProgress(60);
      setLoadingText("Transcribing..."); // Update loading text

      // Set the media URL for playback
      setMediaUrl(URL.createObjectURL(file));

      // Allow time for processing
      await new Promise((resolve) => setTimeout(resolve, 5000));
      setProgress(80);
      const transcribeData = await getOcrText({ source_id: uploadURL.source_id }).unwrap();
      setTranscribeData(transcribeData);
      setProgress(100);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      CtyShowToast('success', 'File Transcribed successful');

    } catch (error) {
      CtyShowToast('error', error?.message ?? 'Failed to process image');
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };
  const handleTimestampClick = (timestamp: number) => {
    if (mediaRef.current) {
      mediaRef.current.currentTime = timestamp;
      mediaRef.current.play();
    }
  };
  const validateFile = (file: File) => {

    if (!transcribeAllowedFiles.includes(file.type)) {
      alert(`File type ${file.type} is not allowed.`);
      return false;
    }
    setUploadedFileType(file.type);
    return true;
  };

  const handleError = (error: string) => {
    console.error(error);
  };
  const handleRemoveFile = (fileName: string) => {
    setMediaUrl(null);
    setTranscribeData([]);
    setUploadedFileType('');
    setProgress(0);
  }
  const formatTimestamp = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };
  const isVideoFile = (fileType: string) => {
    return fileType.startsWith("video/");
  };

  const isAudioFile = (fileType: string) => {
    return fileType.startsWith("audio/");
  };
  const renderMediaElement = (uploadedFileType: string, mediaUrl: string, mediaRef: React.RefObject<HTMLMediaElement>) => {
    if (isAudioFile(uploadedFileType)) {
      return (
        <audio
          ref={mediaRef}
          controls
          src={mediaUrl}
          className="absolute top-0 left-0 w-full h-full border-none"
          controlsList="nodownload nocaptions"
        >
        </audio>
      );
    } else {
      return (
        <video
          ref={mediaRef}
          controls
          src={mediaUrl}
          className="absolute top-0 left-0 w-full h-full border-none"
          controlsList="nodownload nocaptions"
          disablePictureInPicture
        >
        </video>
      );
    }
  };
  return (
    <div className="flex flex-col sm:w-[100%] md:w-auto lg:w-auto xl:w-auto h-full overflow-y-scroll gap-3 lg:flex-row min-h-screen mb-4 transcriber-agent">
      {/* Image Viewer Section */}
      <div className="flex-1 lg:w-1/2 overflow-y-auto">
        <div className="bg-white rounded-lg p-3 mb-4 overflow-y-auto">
          <h1 className="text-xl lg:text-2xl font-bold text-gray-800 mb-4 lg:mb-6">Transcriber</h1>

          <CommonFileUpload
            onFileUpload={handleFileUpload}
            validateFile={validateFile}
            onError={handleError}
            uploadButtonText="Select Files"
            dropzonePlaceholder="Click to upload and Drag and drop"
            fileInputRef={fileInputRef}
            setTranscribeData={setTranscribeData}
            handleRemoveFile={handleRemoveFile}
            loadingText={loadingText}
            loadingProgress={progress}
          />
          <div className="flex justify-center items-center w-full max-w-4xl mx-auto p-4">
            {mediaUrl && (
              <div
                className={`relative w-full pt-[56.25%] rounded-lg overflow-hidden ${isVideoFile(uploadedFileType) ? "bg-black" : ""
                  }`}
              >
                {transcribeAllowedFiles.includes(uploadedFileType) ? (
                  renderMediaElement(uploadedFileType, mediaUrl, mediaRef)
                ) : (
                  <p>Unsupported file type.</p>
                )}
              </div>
            )}
          </div>

        </div>
      </div>

      {/* Data Display Section */}
      <div className="flex-1 p-4 mb-16 lg:w-1/2 overflow-y-auto border-t lg:border-l lg:border-t-0 border-gray-200">
        <h2 className="text-lg lg:text-xl font-bold ">Transcribed Text</h2>

        {
          transcribeData.length > 0 && transcribeData.map((data: any, index: number) => (
            <div key={data.transcript} className="bg-white rounded-lg p-3 mb-4">
              <div className="text-gray-800 text-lg lg:text-md">
                <CtyButton className="text-blue-400 cursor-pointer text-md active:bg-transparent" onPress={() => handleTimestampClick(data.start_time)} variant="light">[{formatTimestamp(data.start_time)} - {formatTimestamp(data.end_time)}]</CtyButton>: {data.transcript}
              </div>
            </div>
          ))
        }

      </div>
    </div>
  );
}

export { Transcriber };
