import { Avatar, Button, Listbox, ListboxItem, Popover, PopoverContent, PopoverTrigger, Tooltip } from '@nextui-org/react';
import { Icon } from '@iconify/react';
import { RootState, useGetUserOrgsQuery } from '@circuitry-ai/doc-data';
import { useSelector } from 'react-redux';
import { getUserInitials } from '../utils/commonutils';
import { CtyButton } from '../button/CtyButton';

interface SwitchOrgsProps {
    isCollapsed: boolean;
    doLogout: () => void;
    doLogin?: () => void;
    toggleSideNav: () => void;
    switchOrganisation: (name: string) => void;
}

interface OrgData {
    display_name: string,
    id: string,
    metadata: {
        cloud_setup_status: string,
        tenant_id: string;
    },
    name: string;
}

const SwitchOrgs = (props: SwitchOrgsProps) => {

    const { isCollapsed, doLogout, doLogin, toggleSideNav, switchOrganisation } = props;
    const { details: user } = useSelector((state: RootState) => state.auth.user);
    const token = useSelector((state: RootState) => state.auth.token);
    const { data: userOrgsList, isLoading: isUserOrgsLoading } = useGetUserOrgsQuery(user?.sub, { skip: !user?.sub || !token });

    if (isUserOrgsLoading) return;
    const userInitial = getUserInitials(user?.name);

    const userAuthAction = () => {
        if (user?.sub) {
            doLogout();
        } else {
            doLogin && doLogin();
        }
    };
    return (
        <div className="border-t-2  py-2 cursor-pointer">
            <Popover placement={'top'} >
                <PopoverTrigger>
                    {isCollapsed ?
                        <div className='py-2 flex items-center justify-center'>
                            <Button isIconOnly radius='full' onPress={toggleSideNav}>
                                <Avatar
                                    size={'md'}
                                    showFallback
                                    src={user?.picture ?? 'https://images.unsplash.com/broken'}
                                    name={userInitial}
                                    alt="Profile"
                                    classNames={{ base: 'bg-[#f0f1f2] text-[#475467]' }}
                                />
                            </Button>
                        </div>
                        :
                        <div className='flex flex-row items-center justify-between bg-rsed-300 w-[270px] p-3'>
                            <Tooltip content={'Switch Organization'} color="warning" showArrow>
                                <div className='flex flex-row gap-3 items-center'>
                                    <Avatar
                                        size={'md'}
                                        showFallback
                                        src={user?.picture ?? 'https://images.unsplash.com/broken'}
                                        name={userInitial}
                                        alt="Profile"
                                        classNames={{ base: 'bg-[#f0f1f2] text-[#475467]' }}
                                    />
                                    <div>
                                        <div className='font-poppins text-sm font-medium leading-5 text-left'>{user?.name ?? 'Guest'}</div>
                                        <div className="font-poppins text-sm font-normal leading-5 text-left">{user?.org_name ?? ''}</div>
                                    </div>
                                </div>
                            </Tooltip>
                            <CtyButton
                                tooltipContent={user?.sub ? 'Logout' : 'Login'}
                                toolTipColor='warning'
                                id={`user-authAction`}
                                isIconOnly
                                radius="full"
                                size="sm"
                                variant="light"
                                onPress={userAuthAction}
                            >
                                <Icon icon="ion:log-out-outline" width="24" height="24" style={{ color: "#667085" }} />
                            </CtyButton>
                        </div>
                    }
                </PopoverTrigger>
                <PopoverContent className='w-[250px] border-0 border-radius-0 shadow-none'>
                    <Listbox aria-label="user-orgs" onAction={(selected) => switchOrganisation(selected.toString())}>
                        {(userOrgsList ?? []).map((org: OrgData, index: number) => {
                            const initials = getUserInitials(org.display_name);
                            return <ListboxItem key={org.name} title={org.display_name} value={org.display_name} endContent={<Icon icon="mynaui:refresh" width="18" height="18" style={{ color: "#667085" }} />}
                                startContent={<Avatar
                                    size={'md'}
                                    showFallback
                                    name={initials}
                                    alt="Profile"
                                    classNames={{ base: 'bg-[#f0f1f2] text-[#475467]', }}
                                />} />;
                        })}
                    </Listbox>
                </PopoverContent>
            </Popover>
        </div>
    );
};

export { SwitchOrgs };